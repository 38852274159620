<template>
  <div class="system-side-nav">
    <div class="side-nav-user-info">
      <img :alt="$t('tou-xiang')" src="../../../assets/head2.png">
      <p class="domain">{{ userInfo.username }}</p>
    </div>
    <a-menu v-model="currentKey" mode="inline" :default-open-keys="['account']">
      <a-menu-item v-for="item in mySystemMenuItems" :key="item.key">
        <a :href="item.href">{{ item.label }}</a>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SideNav',
  data() {
    return {
      menuItemKeys: [],
      currentKey: []
    };
  },
  created() {
    window.$bus.on('changeSidebar', (name) => {
      console.log('changeSidebar');
      this.currentKey = [name];
    });
  },
  watch: {
    '$route.path': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          const pathArr = newVal.split('/');
          const length = pathArr.length;
          if (length === 2) {
            this.currentKey = [`/${pathArr[1]}`];
          } else if (length >= 3) {
            this.currentKey = [`/${pathArr[1]}/${pathArr[2]}`];
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  // mounted() {
  //   const pathArr = this.$route.path.split('/');
  //   const length = pathArr.length;
  //   if (length === 2) {
  //     this.currentKey = [`/${pathArr[1]}`];
  //   } else if (length >= 3) {
  //     this.currentKey = [`/${pathArr[1]}/${pathArr[2]}`];
  //   }
  // },
  destroyed() {
    window.$bus.off('changeSidebar');
  },
  computed: {
    ...mapGetters([
      'includesCC',
      'includesDM'
    ]),
    ...mapState(['myCatLog', 'userInfo', 'globalSetting', 'mySystemMenuItems'])
  }
};
</script>

<style lang="less" scoped>
.system-side-nav {
  background: #F4F4F4;
  //min-width: 160px;
  //max-width: 160px;
  position: fixed;
  top: 48px; /* 菜单栏下方 */
  width: 160px;
  height: calc(100vh - 48px);

  .side-nav-user-info {
    text-align: center;
    padding: 30px 0 20px 0;
    background-image: url("../../../assets/bg-img.png");

    img {
      width: 60px;
      height: 60px;
    }

    .domain {
      margin-top: 15px;
      font-size: 14px;
    }

  }

  .ant-menu {
    background: #F4F4F4;

    .ant-menu-item-selected {
      background: #DDDDDD;
      font-weight: 500;
      font-family: PingFangSC-Semibold;
      position: relative;

      &::before {
        content: '';
        height: 100%;
        width: 3px;
        background: #333333;
        position: absolute;
        left: 0;
        top: 0;
      }

      a {
        color: #333333;
      }
    }

    .ant-menu-item > a {
      color: #333333;
    }
  }
}
</style>
