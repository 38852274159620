<template>
  <div class="system">
    <side-nav  :menu-items="userInfo.menuItems"/>
    <div class="content" :style="`padding: ${noPadding ? '0' : '16px'}`">
      <router-view/>
    </div>
  </div>
</template>

<script>
import SideNav from '@/views/system/components/SideNav';
import { mapState } from 'vuex';

export default {
  name: 'System',
  components: { SideNav },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  watch: {
    '$route.path': {
      handler(newVal) {
        // 用来辅助layout，全部重构完成后移除
        this.noPadding = ['/system/env', '/system/operation_log', '/system/role', '/system/sub_account', '/system/product_list'].includes(newVal);
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      noPadding: false
    };
  }
};
</script>

<style lang="less" scoped>
.system {
  width: 100%;
  display: flex;
  flex: 1;

  .content {
    flex: 1;
    margin-left: 160px; /* 与目录宽度相同 */
    height: calc(100vh - 48px); /* 视口高度减去菜单栏高度 */
    overflow-y: auto; /* 超出内容滚动 */
  }
}
</style>
